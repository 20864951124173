import React from "react";
import "../styles/events.css"

import TitleCardBackground from "../assets/events/eventVolleyball.png"
// import FutureEvent from "../assets/events/futureEvent.png"

//event images
import FutureEvent from "../assets/events/futureEvent.png"
import MainEvent from "../assets/events/spring-2025/Main Event Fun Cards Supporting.png"
import SecretValentine from "../assets/events/spring-2025/secret valentine flyer.png"
import Generalbodymeet from "../assets/events/spring-2025/GBM2.png"


//helpers
import {
    Post,
    // UpcomingPost,
} from "./helpers/eventsHelpers"
import usePageTracking from "../components/Common/TrafficTracker";

const Events = () => {
    usePageTracking("Events Page");

    return (
        <>
        <div className="eventTitleCard"
            style={{backgroundImage: `url(${TitleCardBackground})`}}
        >
            <div className="eventColorOverlay"/>
            <h1>EVENTS</h1>
        </div>
        <div className="eventPostList">
            <h1>What's Next?</h1> 
            <h3>Read about our future events!</h3>

            <div className="postContainer"> {/*something something more manual input*/}
            <Post
                image={MainEvent}
                eventMonth="FEB"
                eventDay="1 - 23"
                eventTime=""
                eventLocation="Main Event"
                postTitle="Main Event Giveback"
                postDesc="Interested in going to Main Event? Give back half of the cost to SASE by purchasing through https://grouprai.se /mainevent54621s!"
                />     
                <Post
                image={SecretValentine}
                eventMonth="FEB"
                eventDay="3 - 11"
                eventTime=""
                eventLocation="Scan QR"
                postTitle="Secret Valentine"
                postDesc="Share some love and sweetness with SASE! Scan the QR code for more info!"
                />  
                <Post
                image={Generalbodymeet}
                eventMonth="FEB"
                eventDay="18"
                eventTime="6:00 PM - 7:30 PM"
                eventLocation="PFT 1202"
                postTitle="GBM 2: Blind Boxes"
                postDesc="Come to our Second Spring General Body Meeting! The theme is Blind Boxes, so come equipped with your most iconic/favorite headwear (you can literally wear anything, i.e. jaden smith grammys)! See y'all there!"
                />        
            </div>
                <div className="postContainer"> {/*something something more manual input              


                {/* <Post //never remove (this is the future event)
                image={FutureEvent}
                eventMonth="Some Month!"
                eventDay="xx"
                eventTime="Eventually!"
                eventLocation="Some Location!"
                postTitle="Future Event"
                postDesc="Our officers are currently formulating a new event! Either that or you're reading this page during the summer or winter. Check back soon! "
                /> */}
                {/* uncomment above if theres no events */}
            </div>
        </div>
        </>
    )
}

export default Events;